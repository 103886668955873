body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* h1 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  clear: both !important;
  font-size: 2.4rem;
  text-align: center;
  vertical-align: middle;
  color: rgb(11, 22, 21);
  font-family: "Kanit", sans-serif;
  width: 100%;
  margin: 10px auto 20px;

  background-image: linear-gradient(180deg, rgb(238, 15, 15), #d3e0e2);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  filter: drop-shadow(4px 4px #374b50);
} */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a {
  color: #0452a7;
}

.twitterIcon,
a.twitterIcon {
  color: #6cadde !important;
}

li {
  list-style-type: none;
  text-align: left;
}

.content {
  margin: 1% auto;
  max-width: 95%;
}

div.spinner-border {
  margin: 15% 0% 2% 0%;
  width: 5rem;
  height: 5rem;
}

div.spinner {
  text-align: center;
  margin: 0 auto;
}

span.sr-only {
  font-size: 3em;
  margin: 0 auto;
}

.Jumbo {
  padding: 0;
}
.jumbotron {
  background-image: url("./static/Germany-Sweden-Algarve-cup-2020-copy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  height: 25vh;
}

@media all and (min-width: 320px) {
  div.wrapper.container-fluid {
    padding-right: 0;
  }
  /* h1 {
    font-size: 2.6rem;
  } */
}

@media all and (min-width: 768px) {
  /* h1 {
    font-size: 2.9rem;
  } */
  .jumbotron {
    height: I 35vh;
  }
}

@media all and (min-width: 1024px) {
  /* h1 {
    font-size: 3.3rem;
  } */
  .jumbotron {
    height: 40vh;
  }
}
