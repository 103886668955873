div.banner-container {
  clear: both !important;
  width: 100%;
  height: 40%;
  padding: 1%;
  color: #200f08;
  background: #e5e5e5;
  text-align: center;
  margin-bottom: 0.2%;
  z-index: 2;
}

p.banner {
  vertical-align: middle;
  max-width: 90%;
  margin: 5px auto;
  font-weight: 500;
}

@media all and (min-width: 1024px) {
  div.banner-container {
    margin: -2% 0 0 0;
  }
}
