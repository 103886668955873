a:hover {
  text-decoration: none;
}

div.navRow.row {
  background: #e5e5e5;
}

.main-nav {
  font-size: 0.9em;
  text-align: start;
}

/* .navbar-expand-lg {
  justify-content: clear;
} */

span.navbar-text {
  padding: 0 15% 0 15%;
  font-size: 1.4em;
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: #000000;
  padding: 0 35px 0 0;
}

a.nav-link,
a.dropdown-toggle.nav-link {
  font-size: 1.4em;
  padding: 0 15% 0 15%;
  color: #000000;
}

@media all and (min-width: 768px) {
  .main-nav {
    font-size: 0.8em;
    text-align: center;
  }
}

@media all and (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-direction: row-reverse;
  }
  .main-nav {
    font-size: 0.7em;
  }
}

/* @media all and (min-width: 1024px) {
  .main-nav {
    font-size: 0.8em;
  }
} */

@media all and (min-width: 1200px) {
  .main-nav {
    font-size: 0.9em;
  }
}

/* @media all and (min-width: 1440px) {
  .main-nav {
    font-size: 0.8em;
  }
} */
