div.container {
    clear: both !important;
    max-width: 100%;
    height: 40%;
    padding: 15px;
    color: #200f08;
    background: #f8f6f6;
    margin: 5% 0 0 0;
}

div#footerNav.justify-content-end.nav {
    padding: 0 1% 0 0;
    margin: 0% 1% 0 0;
}

div#justify-content-end nav{
    text-decoration: none;
}

/* Medium screens */
@media all and (min-width: 600px) {
    
}
  
/* Large screens */
@media all and (min-width: 768px) {
    div.container {
        margin: 3% 0 0 0;
    }
}

@media all and (min-width: 1024px) {
    div.container {
        margin: 2% 0 0 0;
    }
}