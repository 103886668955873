footer.container {
  max-width: 100%;
  clear: both !important;
  height: 40%;
  padding: 15px; 
  color: #200f08;
  background: #E5E5E5;
  margin: 0% 0 0 0;
}

div#copyright{
  width: 100%;
  text-align: left;
}

footer a {
  color: #841F06;
  /* font-size: 0.9rem; */
  font-weight: 600;
}

footer a:hover {
  color: #00458F;
  font-weight: 850;
}
img.algoliaImage{
  width: 18%;
}

  /* Medium screens */
  @media all and (min-width: 576px) {
    img.algoliaImage{
      width: 15%;
    }
  }
  
  /* Large screens */
  @media all and (min-width: 768px) {
    img.algoliaImage{
      width: 10%;
    }
  }
    
  @media all and (min-width: 1024px) {
    img.algoliaImage{
      width: 7%;
    }
  }

  @media all and (min-width: 1280px){
    img.algoliaImage{
      width: 6%;
      margin: 10px 0 0 0;
    }
  }
